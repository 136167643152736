import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { getPath, makePlaylist } from 'api/client';
import { useSelector } from 'react-redux';
import { put, select, takeLatest } from 'redux-saga/effects';
import { runFetch } from 'utils/sliceUtils';

function* generatePlaylist() {
  const {
    user: { ID, token },
    playlist: { tracks, paths },
    library: {
      library: { trackInfo }
    }
  } = yield select();
  const pathsNew = [...paths];
  for (let i = 0; i < pathsNew.length - 1; i++) {
    if (pathsNew[i] === 'loading') {
      pathsNew[i] = getPath({
        source: tracks[i],
        target: tracks[i + 1],
        ID,
        token
      });
    }
  }
  const loadedPaths = yield Promise.all(pathsNew);
  yield put(
    setPaths(
      loadedPaths.map((path) => {
        if (path['path']) {
          return path['path'].filter((trackId) => trackInfo[trackId]);
        }
        return path;
      })
    )
  );
}

export function* generatePlaylistSaga() {
  yield takeLatest(
    ['playlist/addTrack', 'playlist/removeTrack'],
    generatePlaylist
  );
}

const descriptionPrefix =
  'A playlist generated by the Channel Music app using the following songs: ';

export const exportPlaylist = createAsyncThunk(
  'playlist/exportPlaylist',
  async (_, { getState, dispatch }) => {
    const {
      user: { ID, token },
      playlist: { paths, tracks },
      library: {
        library: { trackInfo }
      }
    } = getState();
    const trackNames = tracks
      .map((trackId) => trackInfo[trackId])
      .map((song) => `${song.name} by ${song.artist_name}`);
    const name = `Channel Music Playlist: ${trackNames[0]} to ${
      trackNames[trackNames.length - 1]
    }`;
    const description = descriptionPrefix + trackNames.join(' -> ');
    const response = await runFetch(dispatch, makePlaylist, {
      tracks: paths
        .slice(0, -1)
        .flatMap((path, i) => (i ? path.slice(1) : path)),
      name,
      description,
      ID,
      token
    });
    window.open(response.playlist_url, '_blank', 'noopener,noreferrer');
    return { response };
  }
);

const initialState = {
  tracks: [],
  paths: [],
  selectedTarget: null,
  exportProgress: false
};

export const playlistSlice = createSlice({
  name: 'playlist',
  initialState,
  reducers: {
    clearPlaylist: () => initialState,
    selectTarget: (state, { payload }) => {
      state.selectedTarget = payload;
    },
    setPaths: (state, { payload }) => {
      state.paths = payload;
    },
    addTrack: (state, { payload: { id, index } }) => {
      const { tracks, paths } = state;
      if (index === undefined) {
        index = state.selectedTarget ?? tracks.length;
      }
      state.tracks = [
        ...tracks.slice(0, index),
        id,
        ...tracks.slice(index + 1, tracks.length)
      ];
      state.paths = [
        ...paths.slice(0, index),
        'loading',
        ...paths.slice(index + 1, paths.length)
      ];
      if (index > 0) {
        state.paths[index - 1] = 'loading';
      }
      state.selectedTarget = null;
    },
    removeTrack: (state, { payload: { index } }) => {
      const { tracks, paths } = state;
      state.tracks = [
        ...tracks.slice(0, index),
        ...tracks.slice(index + 1, tracks.length)
      ];
      if (index === paths.length - 1) {
        state.paths = paths.slice(0, index);
      } else if (index > 0) {
        state.paths = [
          ...paths.slice(0, index - 1),
          'loading',
          ...paths.slice(index + 1, paths.length)
        ];
      } else {
        state.paths = paths.slice(1, paths.length);
      }
      state.selectedTarget = null;
    },
    clearTracks: (state) => {
      state.tracks = [];
      state.paths = [];
    }
  },
  extraReducers: (builder) => {
    builder.addCase(exportPlaylist.fulfilled, (state) => {
      state.exportProgress = false;
    });
    builder.addCase(exportPlaylist.pending, (state) => {
      state.exportProgress = true;
    });
    builder.addCase(exportPlaylist.rejected, (state) => {
      state.exportProgress = false;
      alert('Error exporting playlist');
    });
  }
});

const { setPaths } = playlistSlice.actions;
export const {
  addTrack,
  removeTrack,
  clearTracks,
  selectTarget,
  clearPlaylist
} = playlistSlice.actions;

export default playlistSlice.reducer;

export const useSelectPlaylistData = () =>
  useSelector(
    ({ playlist: { tracks, paths, exportProgress, selectedTarget } }) => ({
      tracks,
      paths,
      exportProgress,
      selectedTarget
    })
  );
export const useSelectSelectedTarget = () =>
  useSelector((state) => state.playlist.selectedTarget);
