import React from 'react';
import Header from 'components/Header';

export default function Terms() {
  return (
    <>
      <Header className="Legal__header" />
      <section className="Legal">
        <h1>TERMS OF USE</h1>
        <p>Effective Date: November 1st, 2023.</p>
        <ol>
          <li>
            <h2>Introduction and Overview</h2>
            <p>
              These Terms of Use (“Terms”) set forth a legally binding agreement
              between you and Channel Studio LLC, the entity giving you access
              to https://music.channel.studio, or one of our subsidiaries or
              affiliates (collectively, “Channel,” “we”, “our”, or “us”), and
              govern your use of any online services we control that link to
              these Terms, including all features and content available through
              such online services (collectively, the “Service”). By using the
              Service, you accept and agree to be bound by these Terms. If you
              do not agree to these Terms, do not use the Service.
            </p>
            <p>
              IMPORTANT NOTICE: ARBITRATION AGREEMENT AND CLASS ACTION WAIVER:
              BY AGREEING TO BE BOUND BY THESE TERMS, YOU AGREE THAT ALL
              DISPUTES BETWEEN US WILL BE RESOLVED BY BINDING ARBITRATION,
              EXCEPT FOR CERTAIN LIMITED TYPES OF DISPUTES DESCRIBED IN THE
              ARBITRATION AGREEMENT AND{' '}
              <a href="#class-action-waiver">
                CLASS ACTION WAIVER SECTION BELOW
              </a>
              . YOUR AGREEMENT TO ARBITRATE MEANS YOU ARE GIVING UP THE RIGHT TO
              GO TO COURT AND THE RIGHT TO A TRIAL BY JURY, AND INSTEAD,
              DISPUTES WILL BE DECIDED BY A NEUTRAL ARBITRATOR. YOU ALSO AGREE
              THAT ALL DISPUTES BETWEEN US, WHETHER IN COURT OR IN ARBITRATION,
              WILL BE BROUGHT ONLY ON AN INDIVIDUAL BASIS AND YOU ARE WAIVING
              YOUR RIGHT TO BRING OR PARTICIPATE IN A CLASS ARBITRATION OR CLASS
              ACTION. PLEASE CLICK <a href="#class-action-waiver">HERE</a> TO
              READ THE ARBITRATION AGREEMENT AND CLASS ACTION WAIVER SECTION FOR
              FURTHER DETAILS.
            </p>
            <p>
              In some instances, both these Terms and separate terms elsewhere
              on the Service will apply to your use of the Service (“Additional
              Terms”). To the extent there is a conflict between these Terms and
              any applicable Additional Terms, the Additional Terms will control
              unless they expressly state otherwise.
            </p>
            <p>
              For details on how we process your information, please see our{' '}
              <a href="/privacy-policy">Privacy Policy.</a>
            </p>
          </li>
          <li>
            <h2>Service Use</h2>
            <ol>
              <li>
                <h3>Accounts & Music Streaming Platforms.</h3>
                <p>
                  To use the Service, you must link your accounts on certain
                  third party music streaming platforms (“Streaming Platforms”)
                  in order to retrieve certain data about your use of that
                  service, such as Spotify or Apple Music. If you have linked
                  your Streaming Platform account with our Service, you may
                  unlink your Streaming Platform account(s) at any time by
                  visiting your Channel or Streaming Platform account settings.
                  Please contact us as set out in the{' '}
                  <a href="#contact-us">Contact Us</a> section below if you have
                  any questions.
                </p>
                <p>
                  By linking your Streaming Platform account(s), you are subject
                  to separate terms and policies by such applicable Streaming
                  Platforms. You understand and agree that you have a direct
                  relationship with the Streaming Platform(s), and you should
                  carefully read the applicable terms and privacy policy before
                  connecting your Streaming Platform account with the Service.
                  Information you provide any Streaming Platforms is governed by
                  their privacy policy and the data practices described therein.
                  We encourage you to familiarize yourself with and consult the
                  applicable policies and terms of use in connection with your
                  Streaming Platform account.
                </p>
              </li>
              <li>
                <h3>Content</h3>
                <p>
                  The Service contains: (i) materials and other items relating
                  to Channel and our products or services, and similar items
                  from our licensors and other third parties, including all
                  layout, information, databases, articles, posts, text, data,
                  files, images, scripts, designs, graphics, instructions,
                  illustrations, photographs, sounds, pictures, videos,
                  advertising copy, URLs, technology, software, interactive
                  features, the “look and feel” of the Service, and the
                  compilation, assembly, and arrangement of the materials of the
                  Service and any and all copyrightable material; (ii)
                  trademarks, logos, trade names, trade dress, service marks,
                  and trade identities of various parties, including those of
                  Channel; and (iii) other forms of intellectual property (all
                  of the foregoing, collectively “Content”). All right, title,
                  and interest in and to the Service and the Content is the
                  property of Channel or our licensors or certain other third
                  parties, and is protected by U.S. and international copyright,
                  trademark, trade dress, patent and/or other intellectual
                  property and unfair competition rights and laws to the fullest
                  extent possible.
                </p>
              </li>
              <li>
                <h3>Limited License.</h3>
                <p>
                  Subject to your strict compliance with these Terms and any
                  applicable Additional Terms, Channel grants you a limited,
                  non-exclusive, revocable, non-assignable, and non-transferable
                  license to download and display the Content for your personal,
                  non-commercial use only. The foregoing limited license (i)
                  does not give you any ownership of, or any other intellectual
                  property interest in, any Content, and (ii) may be suspended
                  or terminated for any reason, in Channel’s sole discretion,
                  and without advance notice or liability. Your unauthorized use
                  of the Content may violate copyright, trademark, privacy,
                  publicity, communications, and other laws, and any such use
                  may result in your personal liability, including potential
                  criminal liability. All rights not expressly granted to you
                  are reserved by Channel and its licensors and other third
                  parties. No right or license may be construed, under any legal
                  theory, by implication, estoppel, industry custom, or
                  otherwise.
                </p>
              </li>
              <li>
                <h3>Restrictions.</h3>
                <p>
                  You may not use the Service unless you are at least eighteen
                  (18) years old.
                </p>
                <p>
                  You may not: (i) use the Service or Content for any political
                  or commercial purpose; (ii) engage in any activity in
                  connection with the Service or Content that is unlawful,
                  harmful, offensive, sexually explicit, obscene, violent,
                  threatening, harassing, abusive, falsely representative of
                  your persona, invasive of someone else’s privacy, or otherwise
                  objectionable to Channel; (iii) harvest any information from
                  the Service or Content; (iv) reverse engineer or modify the
                  Service or Content; (v) interfere with the proper operation of
                  or any security measure used by the Service or Content; (vi)
                  infringe any intellectual property or other right of any third
                  party; (vii) use the Service or Content in a manner that
                  suggests an unauthorized association or is beyond the scope of
                  the limited license granted to you; or (viii) otherwise
                  violate these Terms or any applicable Additional Terms. Any
                  unauthorized use of the Service or Content is prohibited.
                </p>
                <p>
                  You agree to comply with all local, state, federal, national,
                  foreign, supranational, and international laws, statutes,
                  ordinances, regulations, treaties, directives, and agreements
                  that apply to your use of the Service and Content.
                </p>
              </li>
              <li>
                <h3>Availability.</h3>
                <p>
                  Channel may suspend or terminate the availability of the
                  Service and Content, in whole or in part, to any individual
                  user or all users, for any reason, in Channel’s sole
                  discretion, and without advance notice or liability. Upon
                  suspension or termination of your access to the Service, or
                  upon notice from Channel, all rights granted to you under
                  these Terms or any applicable Additional Terms will cease
                  immediately, and you agree that you will immediately
                  discontinue use of the Service and Content.
                </p>
              </li>
              <li>
                <h3>Equipment.</h3>
                <p>
                  You are responsible for obtaining and maintaining all
                  connectivity, computer software, hardware and other equipment
                  needed for access to and use of the Service and all charges
                  related to the same.
                </p>
              </li>
              <li>
                <h3>Third Parties.</h3>
                <p>
                  In addition, the Service may contain other content from and
                  links to websites, platforms operated or controlled by other
                  third parties. In addition, we may integrate technologies
                  operated or controlled by third parties into parts of our
                  Service. To the extent permitted by applicable law, we are not
                  responsible for any loss or damage relating to third parties.
                  Please see our <a href="/privacy-policy">Privacy Policy</a>{' '}
                  for further details regarding data processing by third
                  parties.
                </p>
              </li>
            </ol>
          </li>
          <li>
            <h2>Submissions.</h2>
            <p>
              To the extent applicable, you acknowledge that any submissions you
              may make to the Service (i.e., user-generated content including
              but not limited to: music preferences, playlists, comments,
              feedback, messages, or other materials or items) (each, a
              “Submission”) may be edited, removed, modified, published,
              transmitted, and displayed by Channel and you waive any rights you
              may have in having the material altered or changed in a manner not
              agreeable to you.
            </p>
            <p>
              You grant us a non-exclusive, unrestricted, unconditional,
              unlimited, worldwide, irrevocable, perpetual, transferable and
              cost-free right and license to host, store, use, display,
              reproduce, modify, adapt, edit, combine with other materials,
              publish, distribute, create derivative works from, promote,
              exhibit, broadcast, syndicate, sublicense (including to third
              party media channels, platforms, and distributors), publicly
              perform, publicly display, and otherwise use and exploit in any
              manner whatsoever, or grant third parties the right to do any of
              the foregoing, all or any portion of your Submissions, for any
              purpose whatsoever in all formats, on or through any means or
              medium now known or hereafter developed, and with any technology
              or devices now known or hereafter developed, and to advertise,
              market, and promote the same.
            </p>
            <p>
              To the extent permitted by applicable law, you further irrevocably
              grant us the right, but not the obligation, to use your name in
              connection with your Submissions. You also agree to waive any
              right of approval for our use of the rights granted herein and
              agree to waive any moral rights that you may have in any
              Submissions, even if it is altered or changed in a manner not
              agreeable to you. To the extent not waivable, you irrevocably
              agree not to exercise such rights in a manner that interferes with
              any exercise of the granted rights.
            </p>
            <p>
              You understand that you will not receive any fees, sums,
              consideration, or remuneration for any of the rights granted in
              this Section. Our receipt of your Submissions is not an admission
              of their novelty, priority, or originality, and it does not impair
              our right to existing or future intellectual property rights
              relating to your Submissions.
            </p>
            <p>
              You represent and warrant that you own or have the necessary
              rights, licenses, consents, and permissions to grant us the rights
              granted in this Section. You alone, though, retain whatever
              legally cognizable right, title, and interest that you have in
              your Submissions and remain responsible for them.
            </p>
          </li>
          <li>
            <h2>Copyright Infringement</h2>
            <p>
              Channel responds to claims of copyright infringement submitted to
              our designated agent under the Digital Millennium Copyright Act,
              17 U.S.C. § 512. Our designated agent is:
            </p>
            <ul className="Legal__address">
              <li>Attn: Channel Studio</li>
              <li>Inc. PO Box 67, Arkville, NY 12406</li>
              <li>Email: cm@channel.studio</li>
            </ul>
            <p>
              To submit a notice of claimed copyright infringement under U.S.
              law, provide our designated agent with the following written
              information:
            </p>
            <ul>
              <li>
                A physical or electronic signature of the copyright owner or a
                person authorized to act on his or her behalf;
              </li>
              <li>
                Identification of the copyrighted work claimed to have been
                infringed;
              </li>
              <li>
                Identification of the infringing material and information
                reasonably sufficient to permit us to locate that material;
              </li>
              <li>
                Your contact information, including your address, telephone
                number, and an e-mail address;
              </li>
              <li>
                A statement that you have a good faith belief that the use of
                the material in the manner asserted is not authorized by the
                copyright owner, its agent, or the law; and
              </li>
              <li>
                A statement that the information in the notification is
                accurate, and, under penalty of perjury, that you are authorized
                to act on behalf of the copyright owner.
              </li>
            </ul>
          </li>
          <li>
            <h2>Disclaimer of Representations and Warranties.</h2>
            <p>
              TO THE EXTENT PERMITTED BY APPLICABLE LAW, THE SERVICE IS PROVIDED
              TO YOU ON AN “AS IS” BASIS, AND CHANNEL, ITS AFFILIATES, AND THEIR
              RESPECTIVE OFFICERS, DIRECTORS, EMPLOYEES, AGENTS, SUPPLIERS, AND
              LICENSORS (COLLECTIVELY, THE "<em>CHANNEL PARTIES</em>") DO NOT
              MAKE ANY REPRESENTATIONS, WARRANTIES, OR ENDORSEMENTS OF ANY KIND
              WHATSOEVER, WHETHER EXPRESS OR IMPLIED, STATUTORY OR OTHERWISE, OR
              ARISING FROM COURSE OF DEALING, COURSE OF PERFORMANCE, OR USAGE OF
              TRADE, INCLUDING THE IMPLIED WARRANTIES OF TITLE,
              NON-INFRINGEMENT, MERCHANTABILITY, FITNESS FOR A PARTICULAR
              PURPOSE, QUIET ENJOYMENT, AND FREEDOM FROM COMPUTER VIRUS. THERE
              MAY BE DELAYS, OMISSIONS, INTERRUPTIONS, AND INACCURACIES IN THE
              CONTENT OR OTHER MATERIAL MADE AVAILABLE THROUGH THE SERVICE. YOU
              SHOULD NOT RELY ON THE CONTENT AS A SUBSTITUTE FOR, NOR DOES IT
              REPLACE, PROFESSIONAL LEGAL, FINANCIAL, TAX, OR MEDICAL ADVICE.
            </p>
            <p>
              TO THE EXTENT PERMITTED BY APPLICABLE LAW, YOU AGREE THAT YOU WILL
              NOT BE PERMITTED TO OBTAIN AN INJUNCTION OR OTHER EQUITABLE RELIEF
              OF ANY KIND, SUCH AS ANY COURT OR OTHER ACTION THAT MAY INTERFERE
              WITH OR PREVENT THE DEVELOPMENT OR EXPLOITATION OF ANY WEBSITE,
              APPLICATION, CONTENT, SUBMISSIONS, PRODUCT, SERVICE, OR
              INTELLECTUAL PROPERTY OWNED, LICENSED, USED OR CONTROLLED BY
              CHANNEL OR A LICENSOR OF CHANNEL.
            </p>
            <p>
              IF APPLICABLE LAW DOES NOT ALLOW ALL OR ANY PART OF THE
              DISCLAIMERS TO APPLY TO YOU, THE DISCLAIMERS WILL APPLY TO YOU
              ONLY TO THE EXTENT PERMITTED BY APPLICABLE LAW.
            </p>
          </li>
          <li>
            <h2>Limitation of Liability.</h2>
            <p>
              TO THE EXTENT PERMITTED BY APPLICABLE LAW, IN NO EVENT WILL THE
              CHANNEL PARTIES BE LIABLE (JOINTLY OR SEVERALLY) TO YOU FOR ANY
              LOSS, DAMAGE OR INJURY OF ANY KIND INCLUDING ANY DIRECT, INDIRECT,
              SPECIAL, INCIDENTAL, EXEMPLARY, CONSEQUENTIAL OR PUNITIVE LOSSES
              OR DAMAGES, OR DAMAGES FOR SYSTEM FAILURE OR MALFUNCTION OR LOSS
              OF PROFITS, DATA, USE, BUSINESS OR GOOD-WILL, ARISING OUT OF OR IN
              CONNECTION WITH (A) THE SERVICE; (B) THESE TERMS; OR (C) YOUR
              MISUSE OF THE SERVICE OR ANY CONTENT AVAILABLE ON OR THROUGH THE
              SERVICE. THE LIMITATIONS SET FORTH IN THIS SECTION SHALL APPLY
              REGARDLESS OF THE FORM OF ACTION, WHETHER THE ASSERTED LIABILITY
              OR DAMAGES ARE BASED ON CONTRACT, INDEMNIFICATION, TORT, STRICT
              LIABILITY, STATUTE OR ANY OTHER LEGAL OR EQUITABLE THEORY.
            </p>
            <p>
              ANY DISPUTE, CLAIM OR CONTROVERSY ARISING OUT OF OR RELATING IN
              ANY WAY TO THESE TERMS MUST BE COMMENCED WITHIN ONE (1) YEAR OF
              THE RELEVANT EVENTS. TO THE EXTENT PERMITTED BY APPLICABLE LAW,
              YOU AND CHANNEL EACH WAIVE THE RIGHT TO PURSUE ANY DISPUTE, CLAIM
              OR CONTROVERSY RELATING TO THESE TERMS THAT IS NOT FILED WITHIN
              ONE YEAR AND ANY RIGHT YOU OR WE MAY HAVE HAD TO PURSUE THAT
              DISPUTE, CLAIM OR CONTROVERSY IN ANY FORUM IS PERMANENTLY BARRED.
            </p>
            <p>
              IF APPLICABLE LAW DOES NOT ALLOW ALL OR ANY PART OF THE ABOVE
              LIMITATION OF LIABILITY TO APPLY TO YOU, THE LIMITATIONS WILL
              APPLY TO YOU ONLY TO THE EXTENT PERMITTED BY APPLICABLE LAW.
            </p>
          </li>
          <li>
            <h2>Indemnification.</h2>
            <p>
              You agree to defend, indemnify and hold harmless the Channel
              Parties from and against any and all claims, liabilities, damages,
              losses, costs and expenses (including, reasonable attorneys’ fees
              and costs) arising out of or in connection with any of the
              following: (i) any breach or alleged breach by you of these Terms;
              (ii) your Submissions; (iii) your misuse of the Service; (iv) your
              violation of any laws, rules, regulations, codes, statutes,
              ordinances or orders of any governmental or quasi-governmental
              authorities; (v) your violation of the rights of any third party,
              including any intellectual property right, publicity,
              confidentiality, property or privacy right; (vi) any
              misrepresentation made by you; or (vii) your gross negligence or
              willful misconduct. You shall cooperate as fully as reasonably
              required in the defense of any such claim. Channel reserves the
              right, at its own expense, to assume the exclusive defense and
              control of any matter subject to indemnification by you.
            </p>
            <p>
              If applicable law does not allow all or any part of the above
              indemnification obligation to apply to you, the indemnification
              obligation will apply to you only to the extent permitted by
              applicable law.
            </p>
          </li>
          <li id="class-action-waiver">
            <h2>Arbitration Agreement and Class Action Waiver.</h2>
            <p>
              <em>
                PLEASE READ THIS SECTION CAREFULLY – IT MAY SIGNIFICANTLY AFFECT
                YOUR LEGAL RIGHTS, INCLUDING YOUR RIGHT TO FILE A LAWSUIT IN
                COURT AND TO HAVE A JURY HEAR YOUR CLAIMS. IT CONTAINS
                PROCEDURES FOR MANDATORY BINDING ARBITRATION AND A CLASS ACTION
                WAIVER.
              </em>
            </p>
            <ol>
              <li>
                <h3>We Both Agree to Arbitrate.</h3>
                <p>
                  To the fullest extent permitted by applicable law, Channel and
                  you agree to arbitrate all disputes and claims between us,
                  except for Excluded Claims (collectively, “Covered Claims”).
                  This Arbitration Agreement is intended to be broadly
                  interpreted. It includes, but is not limited to:
                </p>
                <ul>
                  <li>
                    claims arising out of or relating to any aspect of the
                    relationship between us, whether based in contract, tort,
                    fraud, misrepresentation or any other statutory or
                    common-law legal theory;
                  </li>
                  <li>claims that arose before this or any prior Agreement;</li>
                  <li>
                    claims for mental or emotional distress or injury not
                    arising out of physical bodily injury;
                  </li>
                  <li>
                    claims that are currently the subject of purported class
                    action litigation in which you are not a member of a
                    certified class; and
                  </li>
                  <li>
                    claims that may arise after the termination of this
                    Agreement.
                  </li>
                </ul>
              </li>
              <li>
                <h3>What is Arbitration.</h3>
                <p>
                  Arbitration is more informal than a lawsuit in court and seeks
                  to resolve disputes more quickly. Instead of a judge or a
                  jury, the case will be decided by a neutral arbitrator who has
                  the power to award the same damages and relief that a court
                  can. Any arbitration under this Agreement will take place on
                  an individual basis; class arbitrations and class actions are
                  not permitted. If any provision of this Arbitration Agreement
                  is found unenforceable, the unenforceable provision shall be
                  severed, and the remaining arbitration terms shall be
                  enforced.
                </p>
              </li>
              <li>
                <h3>Excluded Claims.</h3>
                <p>
                  This Arbitration Agreement shall not require arbitration of
                  the following types of disputes (“<em>Excluded Claims</em>”):
                </p>
                <ul>
                  <li>
                    statutory or common law claims related to intellectual
                    property;
                  </li>
                  <li>claims arising from bodily injury;</li>
                  <li>
                    any claims that may be brought in a small claims court on an
                    individual basis where the claim is properly within the
                    jurisdiction of such court; or
                  </li>
                  <li>
                    a representative action brought on behalf of others under
                    PAGA or other private attorneys general acts, to the extent
                    the representative PAGA Waiver of such action is deemed
                    unenforceable by a court of competent jurisdiction under
                    applicable law not preempted by the FAA.
                  </li>
                </ul>
              </li>
              <li>
                <h3>Arbitration Procedure.</h3>
                <p>
                  The Federal Arbitration Act governs the interpretation and
                  enforcement of this dispute resolution provision. Arbitration
                  shall be initiated through JAMS. Any dispute relating to the
                  Covered Claims will be referred to and finally determined by
                  arbitration in accordance with the JAMS Streamlined
                  Arbitration Rules and Procedures in front of one arbitrator.
                  If there is a conflict between JAMS Rules and the rules set
                  forth in these Terms, the rules set forth in this Terms will
                  govern. The JAMS Rules and instructions for how to initiate an
                  arbitration are available from JAMS at http://www.jamsadr.com
                  or 1-800-352-5267. To initiate arbitration, you or Channel
                  must do the following things:
                </p>
                <ol>
                  <li>
                    Write a demand for Arbitration. The demand must include a
                    description of the Claim and the amount of damages sought to
                    be recovered. You can find a copy of a demand for
                    Arbitration at www.jamsadr.com.
                  </li>
                  <li>
                    Send three copies of the demand for Arbitration, plus the
                    appropriate filing fee to: JAMS to your local JAMS office or
                    to JAMS, Two Embarcadero Center, Suite 1500, San Francisco,
                    CA 94111.
                  </li>
                  <li>
                    Send one copy of the demand for Arbitration to the other
                    party.
                  </li>
                </ol>
                <p>
                  Payment of all filing, administration and arbitrator fees will
                  be governed by the JAMS Rules. The arbitration hearing may be
                  by telephone or in-person. Disputes may also be resolved by
                  submission of documents and without in-person or telephonic
                  hearings as provided by the Rules. To the extent necessary,
                  the locale of the hearing will be the county of the consumer’s
                  home address or, at the consumer’s election, such other locale
                  as mutually agreed to by the parties, or as determined by the
                  arbitrator. Each party will bear their own costs of
                  arbitration unless the arbitrator directs that bearing such
                  costs would be an undue burden and in that case, we will pay
                  for your portion of the arbitration administrative costs (but
                  not your attorneys’ fees). Arbitration under this agreement
                  shall be held under Illinois law without regard to its
                  conflict of laws provisions. The arbitration may award on an
                  individual basis the same damages and relief as a court
                  (including injunctive relief). Any judgment on the award
                  rendered by the arbitrator may be entered in any court of
                  competent jurisdiction.
                </p>
              </li>
              <li>
                <h3>Authority of Arbitrator.</h3>
                <p>
                  The arbitrator will decide the rights and liabilities, if any,
                  of you and Channel, and the dispute will not be consolidated
                  with any other matters or joined with any other cases or
                  parties. The arbitrator shall have the authority to grant
                  motions dispositive of all or part of any claim. The
                  arbitrator shall have the authority to award monetary damages
                  and to grant any non-monetary remedy or relief available to an
                  individual under applicable law, the Arbitration Rules, and
                  the Terms. The arbitrator shall issue a written award and
                  statement of decision describing the essential findings and
                  conclusions on which the award is based, including the
                  calculation of any damages awarded. The arbitrator has the
                  same authority to award relief on an individual basis that a
                  judge in a court of law would have. The award of the
                  arbitrator is final and binding upon you and Channel.
                </p>
              </li>
              <li>
                <h3>Waiver of Class Actions.</h3>
                <p>
                  TO THE EXTENT PERMITTED BY LAW, YOU AND CHANNEL AGREE THAT
                  EACH PARTY MAY BRING CLAIMS (WHETHER IN COURT OR IN
                  ARBITRATION) AGAINST THE OTHER ONLY IN AN INDIVIDUAL CAPACITY,
                  AND NOT PARTICIPATE AS A PLAINTIFF, CLAIMANT, OR CLASS MEMBER
                  IN ANY CLASS, COLLECTIVE, CONSOLIDATED, PRIVATE ATTORNEY
                  GENERAL, OR REPRESENTATIVE PROCEEDING.  THIS MEANS THAT YOU
                  AND CHANNEL MAY NOT BRING A CLAIM ON BEHALF OF A CLASS OR
                  GROUP AND MAY NOT BRING A CLAIM ON BEHALF OF ANY OTHER PERSON
                  UNLESS DOING SO AS A PARENT, GUARDIAN, OR WARD OF A MINOR OR
                  IN ANOTHER SIMILAR CAPACITY FOR AN INDIVIDUAL WHO CANNOT
                  OTHERWISE BRING THEIR OWN INDIVIDUAL CLAIM.  THIS ALSO MEANS
                  THAT YOU AND CHANNEL MAY NOT PARTICIPATE IN ANY CLASS,
                  COLLECTIVE, CONSOLIDATED, PRIVATE ATTORNEY GENERAL, OR
                  REPRESENTATIVE PROCEEDING BROUGHT BY ANY THIRD PARTY.
                  NOTWITHSTANDING THE FOREGOING, YOU OR CHANNEL MAY PARTICIPATE
                  IN A CLASS-WIDE SETTLEMENT.
                </p>
              </li>
              <li>
                <h3>Waiver of Jury Trial.</h3>
                <p>
                  TO THE EXTENT PERMITTED BY APPLICABLE LAW, YOU AND Channel
                  AGREE THAT EACH PARTY WAIVES THEIR CONSTITUTIONAL AND
                  STATUTORY RIGHTS TO A TRIAL BY A JURY.
                </p>
              </li>
              <li>
                <h3>Choice of Law/Forum Selection</h3>
                <p>
                  These Terms have been made and shall be construed and enforced
                  in accordance with the laws of the State of Illinois as an
                  agreement wholly performed therein without regard to their
                  conflict of law provisions. For all litigation regarding
                  Excluded Claims and any other controversy or claim for which
                  arbitration is denied, the sole jurisdiction and venue for
                  such litigation will be an appropriate federal or state court
                  located in the County of Cook in the State of Illinois.
                </p>
              </li>
              <li>
                <h3>Opt-Out of Arbitration Agreement.</h3>
                <p>
                  This Arbitration Agreement automatically applies to your use
                  of the Service. You can opt-out of this Arbitration Agreement
                  within 30 days of your first use of the Service. If we make
                  changes to the Arbitration Agreement, you may opt-out of those
                  changes within 30 days of us posting the updated Arbitration
                  Agreement, provided that such opt-out will only apply to the
                  changes and is not an opt-out of arbitration altogether. To
                  opt-out, email us at cm@channel.studio within the 30 day
                  timeframe with the following information: (i) your full legal
                  name, (ii) your complete mailing address, (iii) your phone
                  number, (iv) if applicable, the username or email address
                  associated with any potential account or newsletter; and (v)
                  the approximate date of your initial use of the Service. Such
                  an opt-out email must be sent by you personally, and not by
                  your agent, attorney, or anyone else purporting to act on your
                  behalf. It must include a statement, personally signed by you,
                  that you wish to opt-out to the Arbitration Agreement.
                </p>
              </li>
            </ol>
          </li>
          <li>
            <h2>Changes to Terms.</h2>
            <p>
              We reserve the right, at any time in our sole discretion, to
              modify or replace any part of these Terms and any applicable
              Additional Terms, without prior notice. You agree that we may
              notify you of any updated Terms and any applicable Additional
              Terms by posting them on the Service so that they are accessible
              via a link from the home page, and/or to send you an e-mail to the
              last e-mail address you provided to us. All such changes are
              effective immediately when we post them, or such later date as may
              be specified in the notice of updated Terms and any applicable
              Additional Terms. If you object to any such changes, your sole
              recourse is to cease using the Service.
            </p>
          </li>
          <li id="contact-us">
            <h2>Contact Us.</h2>
            <p>If you have any questions, please contact us:</p>
            <ul>
              <li>By email: cm@channel.studio</li>
              <li>By mail: Channel Music, PO Box 67, Arkville, NY 12406</li>
            </ul>
          </li>
          <li>
            <h2>General Provisions.</h2>
            <ol>
              <li>
                <h3>Consent or Approval.</h3>
                <p>
                  No Channel consent or approval may be deemed to have been
                  granted by Channel without being in writing and signed by an
                  officer of Channel.
                </p>
              </li>
              <li>
                <h3>Notices.</h3>
                <p>
                  All legal notices to us must be mailed to us at the address in
                  the “Contact Us” section above. When you communicate with us
                  electronically, you consent to receive communications from us
                  electronically. You agree that all agreements, notices,
                  disclosures, and other communications that we provide to you
                  electronically satisfy any legal requirement that such
                  communications be in writing.
                </p>
              </li>
              <li>
                <h3>Survival.</h3>
                <p>
                  The provisions of these Terms and any applicable Additional
                  Terms, which by their nature should survive termination of
                  your use of the Service, including sections on Service Use
                  (except for the limited license), Submissions, Copyright
                  Infringement, Products, Disclaimer of Representations and
                  Warranties, Limitation of Liability, Indemnification,
                  Arbitration Agreement and Class Action Waiver, Changes to
                  Terms, and General Provisions, will survive.
                </p>
              </li>
              <li>
                <h3>Support.</h3>
                <p>
                  You acknowledge that the provision of support is at Channel’s
                  sole discretion and that we have no obligation to provide you
                  with customer support of any kind.
                </p>
              </li>
              <li>
                <h3>Severability; Interpretation; Assignment.</h3>
                <p>
                  If any provision of these Terms, or any applicable Additional
                  Terms, is for any reason deemed invalid, unlawful, void, or
                  unenforceable, then that provision will be deemed severable
                  from these Terms or the Additional Terms, and the invalidity
                  of the provision will not affect the validity or
                  enforceability of the remainder of these Terms or the
                  Additional Terms. You hereby waive any applicable statutory
                  and common law that may permit a contract to be construed
                  against its drafter. The summaries of provisions and section
                  headings are provided for convenience only and shall not limit
                  the full Terms. Channel may assign its rights and obligations
                  under these Terms and any applicable Additional Terms, in
                  whole or in part, to any party at any time without any notice.
                  These Terms and any applicable Additional Terms may not be
                  assigned by you, and you may not delegate your duties under
                  them, without the prior written consent of an officer of
                  Channel.
                </p>
              </li>
              <li>
                <h3>Complete Agreement; No Waiver.</h3>
                <p>
                  These Terms, and any applicable Additional Terms, reflect our
                  complete agreement regarding the Service and supersede any
                  prior agreements, representations, warranties, assurances or
                  discussion related to the Service. Except as expressly set
                  forth in these Terms or any applicable Additional Terms, (i)
                  no failure or delay by you or Channel in exercising any of
                  rights, powers, or remedies under will operate as a waiver of
                  that or any other right, power, or remedy, and (ii) no waiver
                  or modification of any term of these Terms or any applicable
                  Additional Terms will be effective unless in writing and
                  signed by the party against whom the waiver or modification is
                  sought to be enforced.
                </p>
              </li>
              <li>
                <h3>International Issues.</h3>
                <p>
                  Channel controls and operates the Service from the U.S., and
                  Channel makes no representation that the Service is
                  appropriate or available for use beyond the U.S. You agree
                  that the United Nations Convention on Contracts for the
                  International Sale of Goods does not apply to these Terms or
                  to any sale of goods carried out as a result of your use of
                  the Service. Software related to or made available by the
                  Service may be subject to export controls of the U.S., and,
                  except as authorized by law, you agree and warrant not to
                  export or re-export the software to any county, or to any
                  person, entity, or end-user subject to U.S. export controls or
                  sanctions.
                </p>
              </li>
              <li>
                <h3>Investigations; Cooperation with Law Enforcement.</h3>
                <p>
                  Channel reserves the right to investigate and prosecute any
                  suspected breaches of these Terms or the Service. Channel may
                  disclose any information as necessary to satisfy any law,
                  regulation, legal process or governmental request.
                </p>
              </li>
              <li>
                <h3>California Consumer Rights and Notices.</h3>
                <p>
                  Residents of California are entitled to the following specific
                  consumer rights information: you may contact the Complaint
                  Assistance Unit of the Division of Consumer Services of the
                  Department of Consumer Affairs by mail at: 400 R St., Suite
                  1080, Sacramento, California, 95814, or by telephone at (916)
                  445-1254. Their website is located at: http://www.dca.ca.gov.
                </p>
              </li>
            </ol>
          </li>
        </ol>
      </section>
    </>
  );
}
