/*
 * LoadingMessages.js
 * author: evan kirkiles
 * created on Tue Apr 18 2023
 * 2023 the nobot space,
 */
import {
  LoadMessages,
  LoadState,
  ErrorMessages,
  ErrorState
} from 'utils/constants';
import { useSelectLoadStatus, useSelectErrorStatus } from 'slices/exploreSlice';
import classNames from 'classnames';
import { useLocation } from 'react-router';
import { CSSTransition, TransitionGroup } from 'react-transition-group';
import { Link } from 'react-router-dom';

export default function LoadingMessages() {
  const loadStatus = useSelectLoadStatus();
  const errorStatus = useSelectErrorStatus();
  const location = useLocation();
  if (loadStatus > LoadState.LibraryLocalLoading && location.pathname !== '/') {
    return null;
  }

  return (
    <TransitionGroup>
      <>
        {errorStatus[0] !== 0 && (
          <div className="Loading__errors">
            <div>
              <p>{`Channel Music has encountered the following error${
                errorStatus.length > 1 ? 's' : ''
              } while processing:`}</p>
              {errorStatus.map((item, ndx) => {
                return <p key={ndx}>{ErrorMessages[item]}</p>;
              })}
              <div>
                <p>
                  <a href={window.location}>Click here</a> to try again.
                </p>
                <p>
                  If the error does not resolve itself, please try again later.
                </p>
              </div>
            </div>
          </div>
        )}
      </>
      {loadStatus !== LoadState.Done && (
        <CSSTransition
          key={'contents'}
          classNames="Transition__fade"
          timeout={200}
        >
          <ul className="Loading__messages">
            {Object.entries(LoadMessages).map(([load, msgs], i) => {
              if (load === LoadState.LoadAtlas.toString()) return null;
              const later = load > loadStatus;
              const ready = load < loadStatus;
              let additional = null;
              if (load === LoadState.LibraryLocalLoading.toString()) {
                additional = (
                  <Link
                    to="/library"
                    className={classNames('Loading__message_link', {
                      'Loading__message_link-later': !ready
                    })}
                  >
                    {'> '}
                    {ready
                      ? 'View your library above.'
                      : 'Library not yet ready.'}
                  </Link>
                );
              }
              return (
                <li
                  key={load}
                  style={{
                    animationDelay: `${i / LoadState.Done}s`
                  }}
                  className={classNames('Loading__message', {
                    'Loading__message-completed': ready,
                    'Loading__message-later': later
                  })}
                >
                  {'> '} {msgs[ready ? 1 : 0]}
                  {additional}
                </li>
              );
            })}
          </ul>
        </CSSTransition>
      )}
    </TransitionGroup>
  );
}
